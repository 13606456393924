import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

export default [
  {
    path: '/',
    name: 'Home',
    meta: { 
      middleware: [guest], 
      layout: 'blank',
      title: 'Home'
    },
    component: () => import(/* webpackChunkName: "home" */ "../views/Home"),
  },{
    path: '/seo-sub-view/:id',
    name: 'PublicSeoSubView',
    props: true,
    meta: { 
      middleware: [guest], 
      layout: 'blank',
      title: 'SeoSubView'
    },
    component: () => import(/* webpackChunkName: "home" */ "../views/public/SeoSubView"),
  }, {
    path: '/login',
    name: 'Login',
    meta: { 
      middleware: [guest], 
      layout: 'blank',
      title: 'Login'
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/Login"),
  }, {
    path: '/forgot-password',
    name: 'Forgot Password',
    meta: { 
      middleware: [guest], 
      layout: 'blank',
      title: 'Forgot Password'
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/ForgotPassword"),
  }, {
    path: '/reset-password/:token',
    props: true,
    name: 'Reset Password',
    meta: { 
      middleware: [guest], 
      layout: 'blank',
      title: 'Reset Password'
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/ResetPassword"),
  },
  {
    path: "/dashboard",
    name: 'dashboard',
    meta: { middleware: [auth], title: "Dashboard" },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/dashboard/Dashboard"),
  },
  {
    path: "/dashboard/tasks",
    name: 'task_overview',
    meta: { middleware: [auth], title: "Task Overview Dashboard", can: 'view task dashboard' },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/dashboard/TaskOverview"),
  },
  {
    path: "/dashboard/page-changes",
    name: 'page_change_overview',
    meta: { middleware: [auth], title: "Page Changes Dashboard", can: 'view page change dashboard' },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/dashboard/PageChangeOverview"),
  },
  {
    path: "/dashboard/articles",
    name: 'published_articles_overview',
    meta: { middleware: [auth], title: "Published Articles Dashboard", can: 'view published articles dashboard' },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/dashboard/PublishedArticlesOverview"),
  },
  {
    path: "/profile",
    name: 'profile',
    meta: { middleware: [auth], title: "Profile" },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/profile/AccountSettings"),
  },
  {
    path: "/projects/:id/details",
    name: 'project_details',
    meta: { middleware: [auth], title: "Project Details", can: 'view projects' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/projects/ProjectDetails"),
  },
  {
    path: "/projects/:id/crawl-view",
    name: 'project_crawl_view',
    meta: { middleware: [auth], title: "Project Crawl View", can: 'use crawl view' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/projects/CrawlView"),
  },
  {
    path: "/projects/:id/low-internal-links-view",
    name: 'project_low_internal_links_view',
    meta: { middleware: [auth], title: "Project Low Internal Links View", can: 'use low internal links view' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/projects/LowInternalLinksView"),
  },
  {
    path: "/projects/:id/keyword-explorer",
    name: 'project_keyword_explorer',
    meta: { middleware: [auth], title: "Keyword Explorer", can: 'use keyword explorer' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/projects/KeywordExplorer"),
  },
  {
    path: "/projects/:id/editorial-view",
    name: 'project_editorial_view',
    meta: { middleware: [auth], title: "Project Editorial View", can: 'use editorial view' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/projects/EditorialView"),
  },
  {
    path: "/projects/:id/seo-view",
    name: 'project_seo_view',
    meta: { middleware: [auth], title: "Project SEO Tools Dashboard", can: 'use seo view' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/projects/SEOView"),
  },
  {
    path: "/projects/:id/content-authority",
    name: 'project_content_authority_view',
    meta: { middleware: [auth], title: "Content Authority View", can: 'use content authority view' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/projects/ContentAuthorityView"),
  },
  {
    path: "/projects/:id/reports",
    name: 'project_reports',
    meta: { middleware: [auth], title: "Project Reports", can: 'use reports' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/projects/reports/Reports"),
  },
  {
    path: "/projects/:id/reports/:report_id",
    name: 'project_report_details',
    meta: { middleware: [auth], title: "Project Report Details", can: 'use reports' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/projects/reports/ReportDetails"),
  },
  {
    path: "/projects/:id/reports/list/:type",
    name: 'project_report_listing',
    meta: { middleware: [auth], title: "Project Report Listing", can: 'use reports' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/projects/reports/ReportsListing"),
  },
  {
    path: "/projects/:id/rank-checker",
    name: 'project_rank_checker',
    meta: { middleware: [auth], title: "Rank Checker", can: 'use rank checker' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/projects/rank-checker/Dashboard"),
  },
  {
    path: "/projects/:id/log-analysis",
    name: 'project_log_analysis',
    meta: { middleware: [auth], title: "Log Analysis", can: 'use log analysis' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/projects/log-analysis/LogAnalysis"),
  },
  {
    path: "/projects/:action?/:id?",
    name: 'projects',
    meta: { middleware: [auth], title: "Projects", can: 'view projects' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/projects/Projects"),
  },
  {
    path: "/pages",
    name: 'pages',
    meta: { middleware: [auth], title: "Pages", can: 'view page details' },
    component: () =>
      import(/* webpackChunkName: "pages" */ "../views/pages/Pages"),
  },
  {
    path: "/pages/:id",
    name: 'page_details',
    props: true,
    meta: { middleware: [auth], title: "Page Details", can: 'view page details' },
    component: () =>
      import(/* webpackChunkName: "pages" */ "../views/pages/PageDetails"),
  },
  {
    path: "/pages/:id/compare",
    name: 'page_compare',
    props: true,
    meta: { middleware: [auth], title: "Page Compare", can: 'view page details' },
    component: () =>
      import(/* webpackChunkName: "pages" */ "../views/pages/PageCompare"),
  },
  {
    path: "/pages/:id/compare-stats",
    name: 'page_compare_stats',
    props: true,
    meta: { middleware: [auth], title: "Page Compare Stats", can: 'view page details' },
    component: () =>
      import(/* webpackChunkName: "pages" */ "../views/pages/PageCompareStats"),
  },
  {
    path: "/admin",
    name: 'admin_dashboard',
    meta: { middleware: [auth], can: 'view admin console', title: "Admin Dashboard" },
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/admin/Dashboard"),
  },
  {
    path: "/users",
    name: 'users',
    meta: { middleware: [auth], can:'manage users', title: "Users" },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/users/Users"),
  },
  {
    path: "/authors",
    name: 'authors',
    meta: { middleware: [auth], can:'manage authors', title: "Authors" },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/authors/AuthorsListing"),
  },
  {
    path: "/todos",
    name: 'todos',
    meta: { middleware: [auth], title: "ToDo's", can: 'manage todos' },
    component: () =>
      import(/* webpackChunkName: "todos" */ "../views/todos/ToDos"),
  },
  {
    path: "/todos/:id",
    name: 'todo_details',
    props: true,
    meta: { middleware: [auth], title: "ToDo Details", can: 'manage todos' },
    component: () =>
      import(/* webpackChunkName: "todos" */ "../views/todos/ToDoDetails"),
  },
  {
    path: "/tools",
    name: 'tools',
    meta: { middleware: [auth], title: "Tools", can: 'use tools' },
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/pages/tools/Tools"),
  },
  {
    path: "/tools/:item_id",
    name: 'tool_details',
    meta: { middleware: [auth], title: "Tool Details", can: 'use tools' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/pages/tools/ToolDetails"),
  },
  {
    path: "/tools/list/:type",
    name: 'tools_listing',
    meta: { middleware: [auth], title: "Tools Listing", can: 'use tools' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/pages/tools/ToolsListing"),
  },
  {
    path: "/prompts/:type",
    name: 'prompts_editor',
    meta: { middleware: [auth], title: "Prompt Templates", can: 'manage tools' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/admin/PromptsEditor"),
  },
  {
    path: "/content-chat",
    name: 'content_chat',
    meta: { middleware: [auth], title: "Content Chat", can: 'use tools' },
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/pages/tools/ContentChat"),
  },
  {
    path: "/content-chat/:id",
    name: 'content_chat_details',
    meta: { middleware: [auth], title: "Content Chat", can: 'use tools' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/pages/tools/ContentChat"),
  },
  {
    path: "/ask-ai/:id",
    name: 'ask_ai_details',
    meta: { middleware: [auth], title: "Ask AI Conversation", can: 'use tools' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/pages/tools/AskAIConversation"),
  },
  {
    path: "/generate-seo-titles",
    name: 'generate_seo_titles',
    meta: { middleware: [auth], title: "Generate SEO Titles", can: 'use tools' },
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/pages/tools/GenerateSEOTitles"),
  },
  {
    path: "/generate-seo-titles/:id",
    name: 'generate_seo_titles_details',
    props: true,
    meta: { middleware: [auth], title: "Generate SEO Titles", can: 'use tools' },
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/pages/tools/GenerateSEOTitles"),
  },
  {
    path: "/update-article/:id",
    name: 'update_article_details',
    props: true,
    meta: { middleware: [auth], title: "Update Article Tool Details", can: 'use tools' },
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/pages/tools/UpdateArticleToolDetails"),
  },
  {
    path: "/prompts/:id",
    name: 'prompts_creator',
    props: true,
    meta: { middleware: [auth], title: "Briefing Template Creator", can: 'manage tools' },
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/admin/PromptsCreator"),
  },
  {
    path: "/wiki",
    name: 'wiki',
    meta: { middleware: [auth], title: "Wiki Knowledge Base", can: 'view wiki' },
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/wiki/Dashboard"),
  },
  {
    path: "/wiki/:page_slug",
    name: 'wiki_root_page',
    props: true,
    meta: { middleware: [auth], title: "Wiki Knowledge Base", can: 'view wiki' },
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/wiki/Dashboard"),
  },
  {
    path: "/wiki/:category_slug/:page_slug",
    name: 'wiki_page',
    props: true,
    meta: { middleware: [auth], title: "Wiki Knowledge Base", can: 'view wiki' },
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/wiki/Dashboard"),
  },
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    meta: { middleware: [auth], title: "Page Not Found" },
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFound"),
  },
]