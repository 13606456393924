import * as API from "@/services/API";

export default {
  getPage(page_id) {
    return API.apiClient.get(`/pages/${page_id}`);
  },

  getLocalPage(page_id) {
    return API.apiClient.get(`/pages/${page_id}/local`);
  },

  getPages(search = '', options = {page: 1, itemsPerPage: 10}, project = null, export_columns = null, status = null) {
    let url = `/pages?page=${options.page}&per_page=${options.itemsPerPage}&search=${search}&project=${project ?? ''}&status=${status ?? ''}`;
    if(options.sortBy && options.sortBy.length > 0) {
      url += `&sort_by=${options.sortBy[0]}&sort_desc=${options.sortDesc && options.sortDesc[0] ? '1' : '0'}`
    }
    if(export_columns) {
      url += `&export_columns=${export_columns}`
      return API.apiClient.get(url, {
        responseType: 'blob',
      });
    }
    return API.apiClient.get(url);
  },

  listPageChangeOverview(options = {page: 1, itemsPerPage: 10}) {
    let url = `/pages/page-changes?page=${options.page}&per_page=${options.itemsPerPage}`;
    if(options.sortBy && options.sortBy.length > 0) {
      url += `&sort_by=${options.sortBy[0]}&sort_desc=${options.sortDesc && options.sortDesc[0] ? '1' : '0'}`
    }
    return API.apiClient.get(url);
  },

  listRecentlyPublishedPages(project = null, options = {page: 1, itemsPerPage: 10}) {
    let url = `/pages/recent?page=${options.page}&per_page=${options.itemsPerPage}&project=${project ?? ''}`;
    if(options.sortBy && options.sortBy.length > 0) {
      url += `&sort_by=${options.sortBy[0]}&sort_desc=${options.sortDesc && options.sortDesc[0] ? '1' : '0'}`
    }
    return API.apiClient.get(url);
  },

  getSpecificPages(pages) {
    let url = `/pages/specific?pages=${pages.join(',')}`;
    return API.apiClient.get(url);
  },

  getCrawlViewPages(period, project, active_filter = null, search = '', search_fields = [], category_filter = null, subcategory_filter = null, editorial_filter = null,  options = {page: 1, itemsPerPage: 10}, export_columns = null, previous_columns = null, export_pages = null) {
    let url = `/pages/crawl-view?period=${period}&page=${options.page}&per_page=${options.itemsPerPage}&search=${search}&project=${project ?? ''}&filter=${active_filter ?? ''}&category_filter=${category_filter ?? ''}&subcategory_filter=${subcategory_filter ?? ''}&editorial_filter=${editorial_filter ?? ''}`;
    if(options.sortBy && options.sortBy.length > 0) {
      url += `&sort_by=${options.sortBy[0]}&sort_desc=${options.sortDesc && options.sortDesc[0] ? '1' : '0'}`
    }
    if(search_fields.length > 0) {
      url += `&search_fields=${search_fields.join(',')}`
    }
    if(export_pages && export_pages.length > 0) {
      url += `&export_pages=${export_pages.join(',')}`
    }
    if(export_columns) {
      url += `&export_columns=${export_columns}`
      if(previous_columns) {
        url += `&previous_columns=${previous_columns}`
      }
      return API.apiClient.get(url, {
        responseType: 'blob',
      });
    }
    return API.apiClient.get(url);
  },

  getCrawlViewPagesStats(period, project, search = '', search_fields = [], category_filter = null, subcategory_filter = null, editorial_filter = null) {
    let url = `/pages/crawl-view-stats?period=${period}&search=${search}&project=${project ?? ''}&category_filter=${category_filter ?? ''}&subcategory_filter=${subcategory_filter ?? ''}&editorial_filter=${editorial_filter ?? ''}`;
    if(search_fields.length > 0) {
      url += `&search_fields=${search_fields.join(',')}`
    }
    return API.apiClient.get(url);
  },

  getPageContentChecks(id, options = {page: 1, itemsPerPage: 10}, hide_below_percent = 10) {
    let url = `/pages/${id}/content-checks?hide_below_percent=${hide_below_percent}&page=${options.page}&per_page=${options.itemsPerPage}`;
    if(options.sortBy && options.sortBy.length > 0) {
      url += `&sort_by=${options.sortBy[0]}&sort_desc=${options.sortDesc && options.sortDesc[0] ? '1' : '0'}`
    }
    return API.apiClient.get(url);
  },

  getPageIncomingLinks(id, options = {page: 1, itemsPerPage: 10}, export_columns = null, anchor_search = '') {
    let url = `/pages/${id}/incoming-links?page=${options.page}&per_page=${options.itemsPerPage}&anchor_search=${anchor_search}`;
    if(options.sortBy && options.sortBy.length > 0) {
      url += `&sort_by=${options.sortBy[0]}&sort_desc=${options.sortDesc && options.sortDesc[0] ? '1' : '0'}`
    }
    if(export_columns) {
      url += `&export_columns=${export_columns}`
      return API.apiClient.get(url, {
        responseType: 'blob',
      });
    }
    return API.apiClient.get(url);
  },

  getPageOutgoingLinks(id, options = {page: 1, itemsPerPage: 10}) {
    let url = `/pages/${id}/outgoing-links?page=${options.page}&per_page=${options.itemsPerPage}`;
    if(options.sortBy && options.sortBy.length > 0) {
      url += `&sort_by=${options.sortBy[0]}&sort_desc=${options.sortDesc && options.sortDesc[0] ? '1' : '0'}`
    }
    return API.apiClient.get(url);
  },

  getPageURLInspections(id, options = {page: 1, itemsPerPage: 10}) {
    let url = `/pages/${id}/url-inspections?page=${options.page}&per_page=${options.itemsPerPage}`;
    if(options.sortBy && options.sortBy.length > 0) {
      url += `&sort_by=${options.sortBy[0]}&sort_desc=${options.sortDesc && options.sortDesc[0] ? '1' : '0'}`
    }
    return API.apiClient.get(url);
  },

  getContentForCheck(page_id, check_id) {
    return API.apiClient.get(`/pages/${page_id}/content/${check_id}`);
  },

  getPageContentForComparison(page_id, left_id, right_id, compare_type = 'text') {
    return API.apiClient.get(`/pages/${page_id}/content_comparison/${left_id}/${right_id}?compare_type=${compare_type}`);
  },

  getPageStatsForComparison(page_id, first_range, second_range) {
    return API.apiClient.post(`/pages/${page_id}/stats_comparison`, {
      first_range: first_range,
      second_range: second_range
    });
  },

  getMultiplePagesStatsForComparison(pages, first_range, second_range) {
    return API.apiClient.post(`/pages/stats-comparison`, {
      pages: pages,
      first_range: first_range,
      second_range: second_range
    });
  },

  //TODO: Should a dedicated internal links service/controller exist
  getInternalLinksCountsToAPage(page_id) {
    return API.apiClient.get(`/pages/${page_id}/internal_links`);
  },
  
  getPageResponseTimeChartData(page_id, period = '7days') {
    return API.apiClient.get(`/pages/${page_id}/response-time-chart?period=${period}`);
  },
  
  checkDuplicateLinksOnPage(page_id) {
    return API.apiClient.get(`/pages/${page_id}/has-duplicate-links`);
  },
  
  schedulePagesScanning(pages) {
    return API.apiClient.post(`/pages/schedule-scanning`, {
      pages: pages
    });
  },
  
  archivePages(pages) {
    return API.apiClient.post(`/pages/archive`, {
      pages: pages
    });
  },
  
  deletePages(pages) {
    return API.apiClient.post(`/pages/delete`, {
      pages: pages
    });
  },
  
  addToEditorialView(pages) {
    return API.apiClient.post(`/pages/add-to-editorial-view`, {
      pages: pages
    });
  },
  
  getAllTags(page_id) {
    return API.apiClient.get(`/pages/${page_id}/all-tags`);
  },
  
  addTagToPage(page_id, tag) {
    return API.apiClient.post(`/pages/${page_id}/add-tag`, {
      tag: tag
    });
  },
  
  removeTagFromPage(page_id, tag_id) {
    return API.apiClient.post(`/pages/${page_id}/remove-tag`, {
      tag_id: tag_id
    });
  },
  
  updatePageClassification(page_id, article_type_id, subcategory_id, main_keyword, fact_checked_id) {
    return API.apiClient.post(`/pages/${page_id}/update-classification`, {
      article_type_id: article_type_id,
      subcategory_id: subcategory_id,
      main_keyword: main_keyword,
      fact_checked_id: fact_checked_id,
    });
  },
  
  findPage(project_id, url) {
    return API.apiClient.get(`/pages/find-page?project=${project_id}&url=${encodeURIComponent(url)}`);
  },

  findRecommendedLinkPages(project_id, search, excluded_pages = []) {
    let excluded_str = excluded_pages.map(function(el, idx) {
      return 'excluded_pages[' + idx + ']=' + el;
  }).join('&');
    return API.apiClient.get(`/pages/find-recommended-pages?project_id=${project_id}&search=${encodeURIComponent(search)}&${excluded_str}`);
  },

  findLocalPages(project_id, search, excluded_pages = []) {
    let excluded_str = excluded_pages.map(function(el, idx) {
      return 'excluded_pages[' + idx + ']=' + el;
  }).join('&');
    return API.apiClient.get(`/pages/find-local-pages?project_id=${project_id}&search=${encodeURIComponent(search)}&${excluded_str}`);
  },

  storePagesChange(data) {
    return API.apiClient.post(`/pages/store-changes`, data);
  },

  updatePageChange(id, data) {
    return API.apiClient.post(`/pages/store-changes/${id}`, data);
  },

  addPagesComment(project_id, page_id, comment) {
    return API.apiClient.post(`/pages/${page_id}/add-comment`, {
      comment: comment
    });
  },

  getCommentsForPage(project_id, page_id) {
    return API.apiClient.get(`/pages/${page_id}/comments`);
  },

  getChangesForPage(page_id, options = {page: 1, itemsPerPage: 10}) {
    let url = `/pages/${page_id}/page-changes?page=${options.page}&per_page=${options.itemsPerPage}`;
    if(options.sortBy && options.sortBy.length > 0) {
      url += `&sort_by=${options.sortBy[0]}&sort_desc=${options.sortDesc && options.sortDesc[0] ? '1' : '0'}`
    }
    return API.apiClient.get(url);
  },

  deleteChangeForPage(change_id) {
    return API.apiClient.post(`/pages/delete-change`, {
      id: change_id
    });
  },

  getFirstPerformanceRecordForPage(page_id) {
    return API.apiClient.get(`/pages/${page_id}/first-performance-record`);
  },

  getFirstKeywordRecordForPage(page_id) {
    return API.apiClient.get(`/pages/${page_id}/first-keyword-record`);
  },

  extractAdditionalSCDataForPage(page_id, start_date, end_date) {
    return API.apiClient.post(`/pages/${page_id}/schedule-data-extraction`, {
      start_date: start_date,
      end_date: end_date
    });
  },

  extractAdditionalKeywordDataForPage(page_id, start_date, end_date) {
    return API.apiClient.post(`/pages/${page_id}/schedule-keyword-data-extraction`, {
      start_date: start_date,
      end_date: end_date
    });
  },

  bulkEditExternalContent(project_id, edits) {
    return API.apiClient.post(`/pages/bulk-edit-external-content`, {
      project_id: project_id,
      edits: edits
    });
  }
};
