<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar
      app
      flat
      absolute
      color="transparent"
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-2">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="d-block me-2"
            @click="isDrawerOpen = !isDrawerOpen"
            title="Toggle Drawer"
          ></v-app-bar-nav-icon>
          <v-text-field
            v-model="search"
            rounded
            dense
            outlined
            prepend-inner-icon="mdi-magnify"
            class="app-bar-search flex-grow-0"
            hide-details
            clearable
          ></v-text-field>

          <v-spacer></v-spacer>

          <v-select
            v-if="can('view projects')"
            class="pa-4"
            :items="$store.state.project.projects"
            label="Selected Project"
            dense
            outlined
            hide-details
            v-model="selectedProject"
            item-text="name"
            item-value="id"
            clearable
          >
          </v-select>
          <v-spacer></v-spacer>

          <!-- Right Content -->
          <router-link v-if="can('view wiki')" :to="{ name: 'wiki' }">
            <v-icon class="ms-6 me-4">
              mdi-help-circle-outline
            </v-icon>
          </router-link>
          <theme-switcher></theme-switcher>
          <notifications></notifications>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main class="pb-0">
      <div class="app-content-container boxed-container pa-2 pb-0">
        <slot class="pb-0"></slot>
      </div>
    </v-main>

    <v-footer
      color="transparent"
      height="30"
      class="pa-0 text-xs ma-0"
    >
      <div class="boxed-container w-full">
        <div class="mx-8 d-flex justify-space-between">
          <v-spacer></v-spacer>
          <span>
            &copy; {{ new Date().getFullYear() }} <a
              href="https://www.trustonamedia.com/"
              class="text-decoration-none"
              target="_blank"
            >Trustona Media</a></span>
        </div>
      </div>
    </v-footer>
    <v-dialog
      v-model="please_wait"
      persistent
      width="300"
      style='z-index:20001;'
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please wait
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="raw_data"
      width="600"
    >
      <RawDataViewerModal v-if="raw_data" :data="raw_data_object" />
    </v-dialog>
  </v-app>
</template>

<script>
import { ref } from 'vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import Notifications from './components/Notifications.vue'
import RawDataViewerModal from '../components/RawDataViewerModal.vue'
import ProjectService from "@/services/ProjectService";
import { mapActions } from 'vuex'
import error_helper from "@/utils/error_helper";

export default {
  mixins: [error_helper],
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    Notifications,
    RawDataViewerModal
  },
  setup() {
    const isDrawerOpen = ref(null)
    const search = ref('')
    const raw_data_object = ref(null)
    const please_wait = ref(false)
    const raw_data = ref(false)

    return {
      isDrawerOpen,
      search,
      please_wait,
      raw_data,
      raw_data_object,
      selectedProject: ref(null),
      shouldUpdate: ref(true),
    }
  },
  mounted() {
    this.handleSearchOnRouteChange();
    window.bus.$on("please_wait", (please_wait) => {
      this.$nextTick(() => {
        this.please_wait = please_wait;
      });
    });
    window.bus.$on("search_reset", () => {
      this.search = "";
    });
    window.bus.$on("raw_data_view", (raw_data_object) => {
      this.raw_data_object = raw_data_object;
      this.raw_data = true;
    });
    window.bus.$on("reload_projects", () => {
      this.reloadProjects();
    });
    this.reloadProjects();
  },
  methods: {
    ...mapActions("project", ['setProject', 'setProjects']),
    reloadProjects() {
      window.bus.$emit('please_wait', true);
      ProjectService.loadFilterProjects()
        .then((response) => {
          this.setProjects(response.data.data);
          window.bus.$emit('please_wait', false);
        })
        .catch((error) => {
          this.handleErrors(error);
        });
    },
    handleSearchOnRouteChange() {
      if(this.$route.query.search) {
        this.search = this.$route.query.search;
      }
    }
  },
  watch: {
    search() {
      this.throttle(() => {
        window.bus.$emit('search', this.search ?? "");
      }, 300);
    },
    "$route": "handleSearchOnRouteChange",
    selectedProject() {
      if(!this.shouldUpdate) return;
      this.setProject(this.selectedProject);
      if(this.selectedProject) {
        this.$router.push({name: 'project_details', params: {id: this.selectedProject}});
      } else {
        this.$router.push({name: 'dashboard'});
      }
    },
    '$store.state.project.project_id': function() {
      this.shouldUpdate = false;
      this.selectedProject = parseInt(this.$store.state.project.project_id);
      this.$nextTick(() => {
       this.shouldUpdate = true;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  //max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
