<template>
  <v-card class="" color="grey lighten-1">
    <v-card-title> 
      <span class="text-h5">Raw Data Viewer</span>
    </v-card-title>
    <v-card-text>
      <v-textarea
        outlined
        name="input-7-4"
        label="Passed data"
        rows="20"
        readonly
        :value="data_formatted"
        ref="textarea"
      ></v-textarea>
    </v-card-text>
  </v-card>
</template>

<script>
import { isString } from '@tiptap/vue-2';

export default {
  props: {
    data: Object | String
  },
  data() {
    return {};
  },
  computed: {
    data_formatted() {
      if(isString(this.data)) {
        return this.data;
      }
      return JSON.stringify(this.data, null, 2);
    }, 
  },
  watch: {
    data() {
      //scroll textarea to top
      this.$nextTick(() => {
        this.$refs.textarea.scrollTop = 0;
      });
    }
  }
};
</script>